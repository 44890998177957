import React from "react";
import "./style.scss";
import TimelineItem from "./TimelineItem/index";

const Timeline: React.FC = () => {
  return (
    <ul className="Timeline">
      <TimelineItem
        date="10/2019-02/2020 "
        position="Frontend developer - mentor"
        org='"iTP group", Tashkent'
        description="• Establish tutor’s course for young programists"
      />

      <TimelineItem
        date="05/2020 - ..."
        position="Fullstack developer "
        org="Freelancer, Tashkent"
        description="
        • Responsible for implementing UI mockups, integrating third
        party React libraries, and handling/creating Redux store data.
        • The back-end use of Node JS, Express JS, MongoDB and
        Mongoose, the complete MERN Stack to provide RESTful API.
        • Maintaining Parent and Child elements by using State and Props
        in React JS.
        • React and Functional Driven Javascript expert in SPA’s, UI
        Components, Mobile web and Performance optimization.
        • Good experience in using components, Forms, Events, Keys,
        Router, Animations and Flux concept.
        "
      />
    </ul>
  );
};

export default Timeline;
