import React from "react";
import "./style.scss";

import githubIcon from "../../images/svg/github_white.svg";
import mailIcon from "../../images/svg/mail_white.svg";

const Presentation: React.FC = () => {
  return (
    <div className="Presentation">
      <h3>Fullstack developer</h3>
      <h4>Ruslan Ablyamitov</h4>
      <a
        href="https://github.com/codeway-0841"
        target="_blank"
        title="My profile on Github"
        rel="noopener noreferrer"
      >
        <img src={githubIcon} height={32} alt="Github" />
      </a>
      <a
        href="mailto:public.codeway@gmail.com"
        title="Send me an Email"
        rel="noopener noreferrer"
      >
        <img src={mailIcon} height={32} alt="Mail" />
      </a>
    </div>
  );
};

export default Presentation;
