import React from "react";
import Screen from "../../components/ui/Screen";
import Container from "../../components/ui/Container";
import Work from "../../components/Work";

import joincoinImg from "../../images/Colored/joincoin.png";
import joinImg from "../../images/Colored/join.png";
import DecorImg from "../../images/Colored/Decor.jpg";
import Uzmdb from "../../images/Colored/uzmdb.png";
import TodoImg from "../../images/Colored/todo.png";
import WorkLinks from "../../components/WorkLinks";

const Portfolio: React.FC = () => {
  return (
    <>
      <Screen id="uzmdb">
        <Container centered>
          <Work
            title="Uzmdb Movie App"
            img={Uzmdb}
            tags={[
              "React",
              "Redux",
              "Redux-Saga",
              "react-router",
              "Hooks",
              "Sass",
              "Typescript",
            ]}
          >
            <p>Uzmdb- Browse for your favorite movie, tv show and actors.</p>
            <p>
              Uzmdb is an online database of information related to films,
              television programs, home videos, video games, and streaming
              content online – including cast, production crew and personal
              biographies, plot summaries, trivia, ratings, and fan and critical
              reviews.
            </p>
            <WorkLinks
              demo="https://uzmdb.netlify.app/"
              repo="https://github.com/iTP-LLC/uzmdb.com"
            />
          </Work>
        </Container>
      </Screen>

      <Screen id="join">
        <Container centered>
          <Work
            title="Join"
            img={joinImg}
            tags={[
              "React",
              "JavaScript",
              "react-router",
              "Redux",
              "CSS Module + Sass",
            ]}
          >
            <p>
              Joinchat is a freeware, cross-platform, cloud-based instant
              messaging (IM) software and application service.{" "}
            </p>
            <p>
              Join messages are heavily encrypted and can self-destruct. Synced.
              Telegram lets you access your chats from multiple devices.
            </p>
            <WorkLinks
              demo="https://github.com/codeway-0841/join-frontend"
              repo="https://github.com/codeway-0841/join-frontend"
            />
          </Work>
        </Container>
      </Screen>

      <Screen id="decor-store">
        <Container centered>
          <Work
            title="Decor Store"
            img={DecorImg}
            tags={[
              "React",
              "Next.js",
              "react-router",
              "Hooks",
              "Sass",
              "Apollo",
              "jest",
              "Typescript",
              "recoil",
            ]}
          >
            <p>A vintage lover's paradise</p>
            <p>
              This home decor and art website specializes in one-of-a-kind
              antiques and rare designer pieces, sourced from dealers all over
              the world. If you have your heart set on a treasure from a
              particular era, start your hunt here.
            </p>
            <WorkLinks
              demo="https://development-decor.vercel.app/"
              repo="https://github.com/codeway-0841/development-decor"
            />
          </Work>
        </Container>
      </Screen>
      <Screen id="decor-store">
        <Container centered>
          <Work
            title="Todo"
            img={TodoImg}
            tags={[
              "React",
              "Redux",
              "Sass",
              "material-ui",
              "Moment",
              "Javascript",
            ]}
          >
            <p>Sample todo app built with the React framework</p>
            <p>
              The project utilises React to create a simple Todo app. Todo Items
              are generated and rendered to the DOM on enter from the
              setFiltered State instead of modifying and mutating the original
              array of Todos during filtering. They can be updated to show they
              are completed/uncompleted. In addition, the user can delete an
              item from the todo list, removing it from the localStorage and
              from the project's State.
            </p>
            <WorkLinks
              demo="https://codeway-0841.github.io/todo-with-redux/"
              repo="https://github.com/codeway-0841/todo-with-redux"
            />
          </Work>
        </Container>
      </Screen>
      <Screen id="joincoin">
        <Container centered>
          <Work
            title="Joincoin"
            img={joincoinImg}
            tags={[
              "React",
              "JavaScript",
              "react-router",
              "Node.js",
              "Koa",
              "MongoDB (mongoose)",
              "Redis",
              "Websocket",
              "EChart",
              "Redux",
              "CSS Module + Sass",
            ]}
          >
            <p>Your one-stop shop for crypto trading</p>
            <p>
              Trade Bitcoin, Ethereum, USDT, and the top altcoins on the
              legendary crypto asset exchange.
            </p>
            <WorkLinks
              demo="https://codeway-0841.github.io/joincoin/"
              repo="https://github.com/codeway-0841/joincoin"
            />
          </Work>
        </Container>
      </Screen>
    </>
  );
};

export default React.memo(Portfolio);
