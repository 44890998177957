import React from "react";

import githubIcon from "../../images/svg/github_white.svg";
import mailIcon from "../../images/svg/mail_white.svg";

import "./style.scss";

const Thanks: React.FC = () => {
  return (
    <div className="Thanks">
      <h1>Thanks for watching!</h1>
      <div className="Thanks--link">
        <img src={githubIcon} height={48} alt="Github" />
        <p>My profile on Github:</p>
        <a
          href="https://github.com/codeway-0841"
          target="_blank"
          title="My profile on Github"
          rel="noopener noreferrer"
        >
          https://github.com/codeway-0841
        </a>
      </div>
      <div className="Thanks--link">
        <img src={mailIcon} height={48} alt="Github" />
        <p>My Email:</p>
        <a
          href="mailto:public.codeway@gmail.com"
          title="Send me an Email"
          rel="noopener noreferrer"
        >
          public.codeway@gmail.com
        </a>
      </div>
    </div>
  );
};

export default Thanks;
